import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  brandClasses: {
    ctasWrapper: 'mt-4 py-6 space-y-4',
    headingProductName: 'elevate-body-1 fw-bold',
    variantSelectionWrapper: 'mt-8 space-y-8'
  },
  details: {
    ctaVariant: 'secondary',
    favoriteCta: {
      variantSelected: 'secondary'
    }
  },
  disableGroupBy: true,
  // TODO: this config could be removed / klarna could be placed where desired in components/pdp/Layout.vue
  showPaymentOptionsAboveCta: true,
  swatches: {
    size: { sm: 'md', lg: 'sm' },
    variant: 'rectangle'
  }
} satisfies RecursivePartial<PdpConfig>
