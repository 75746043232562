import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    carousel: 'lg:gap-2',
    carouselItemFirst: 'lg:col-span-2',
    pagination: 'mb-8 mt-4 lg:hidden'
  },
  showExpandIcon: false,
  showPrevNext: false
} satisfies VfConfig['mediaGallery']
