import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    badge: 'h-6 w-full rounded-t px-2 py-1 duration group-hover:(bg-black c-white)',
    badgeOffset: 'mt-6',
    card: 'group rounded bg-white ring ring-grey-70 ring-inset duration hover:ring-black',
    eyebrow: 'mx-2 mt-2 c-black',
    gallery: 'mb-2 b-x b-grey-70 duration group-hover:b-black',
    galleryNoBadge: 'b-t rounded-t overflow-auto',
    price: 'elevate-body-4 mx-2',
    quickshopCta: 'mx-1 my-2 self-end',
    ratingWrapper: 'mx-2 mt-2',
    swatches: 'mx-1',
    title: 'elevate-body-4 mx-2 mt-2 c-black'
  },
  quickshopCta: {
    iconOnly: true
  },
  showBadgeAboveGallery: true
} satisfies RecursivePartial<ProductConfig['card']>
