import type { QuickshopConfig } from '#types/config/components/dialog/quickshop'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    productNameInline: 'elevate-body-4',
    productNameModal: 'elevate-body-4',
    stickyFooter: 'space-y-2',
    wrapperInline: 'lg:(absolute -top-8 -inset-x-2 z-3 bg-white pt-8 pb-4 px-2 shadow-md) b b-grey-70 rounded'
  },
  ctaSize: 'tiny'
} satisfies RecursivePartial<QuickshopConfig>
