import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    cartIcon: '<lg:max-w-6',
    primaryNavigation: 'gap-3 py-2 <lg:h-15',
  },
  logoVariant: 'full'
} satisfies RecursivePartial<VfConfig['header']>
