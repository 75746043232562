import componentsCheckoutApplePayButton from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/checkout/applePayButton"
import componentsCmsMegaMenu from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/cms/megaMenu"
import componentsDialogFilters from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/dialog/filters"
import componentsDialogQuickshop from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/dialog/quickshop"
import componentsFormSignUp from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/form/signUp"
import componentsProductBadge from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/badge"
import componentsProductCard from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/card"
import componentsProductDetails from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/details"
import componentsProductList from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/list"
import componentsProductPagination from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/pagination"
import componentsProductUpsell from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/product/upsell"
import componentsVfButton from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/button"
import componentsVfColorPicker from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/colorPicker"
import componentsVfDropdown from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/dropdown"
import componentsVfFormSelect from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/form/select"
import componentsVfHeader from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/header"
import componentsVfMediaGallery from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/mediaGallery"
import componentsVfPill from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/pill"
import componentsVfSearch from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/search"
import componentsVfSizePicker from "/opt/build/repo/brands/timberland/regions/nora/config/app/components/vf/sizePicker"
import pagesPdp from "/opt/build/repo/brands/timberland/regions/nora/config/app/pages/pdp"
import pagesPlp from "/opt/build/repo/brands/timberland/regions/nora/config/app/pages/plp"
    
export default {"components":{"checkout":{"applePayButton":componentsCheckoutApplePayButton},"cms":{"megaMenu":componentsCmsMegaMenu},"dialog":{"filters":componentsDialogFilters,"quickshop":componentsDialogQuickshop},"form":{"signUp":componentsFormSignUp},"product":{"badge":componentsProductBadge,"card":componentsProductCard,"details":componentsProductDetails,"list":componentsProductList,"pagination":componentsProductPagination,"upsell":componentsProductUpsell},"vf":{"button":componentsVfButton,"colorPicker":componentsVfColorPicker,"dropdown":componentsVfDropdown,"form":{"select":componentsVfFormSelect},"header":componentsVfHeader,"mediaGallery":componentsVfMediaGallery,"pill":componentsVfPill,"search":componentsVfSearch,"sizePicker":componentsVfSizePicker}},"pages":{"pdp":pagesPdp,"plp":pagesPlp}}
